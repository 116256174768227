
import './LandingPage.scss';
import Header from '../components/header' 
import liquidityImage from '../assets/images/liquidity.svg'
import smartBackgroundImage from '../assets/images/smart-contract.svg'
import eThereUmImage from '../assets/images/e-there-um.svg'
import coreImage from '../assets/images/coreImage.jpg'
import litecoingImage from '../assets/images/litecoinImage.svg'
import keyImage from '../assets/images/keyImage.png'
import mintingImage from '../assets/images/mintingImage.svg'
import yellowVector from '../assets/images/yellow-vector.svg'
import pinkVector from '../assets/images/pink-vector.svg'
import orangeVector from '../assets/images/orange-vector.svg'
import blueVector from '../assets/images/blue-vector.svg'
import wlitecoinLogo from '../assets/images/wlitecoin-logo.svg'
import fireblocksLogo from '../assets/images/fireblocksLogo.svg'
import coinbaseLogo from '../assets/images/coinbaseLogo.svg'
import coinutLogo from '../assets/images/coinutLogo.svg'
import partnersCover from '../assets/images/partnersCover.svg'
import footerBoxLogo from '../assets/images/footer-box-logo.svg'
import facebookLogo from '../assets/images/facebooklogo.svg'
import xLogo from '../assets/images/xlogo.svg'
import redditLogo from '../assets/images/redditlogo.svg'
import telegramLogo from '../assets/images/telegramlogo.svg'
import discordLogo from '../assets/images/discordlogo.svg'

import { useState } from 'react';

const WLTCSteps = [
    {
        "title":"Receiving",
        "description":"To start, Litecoin (LTC) is sent to a secure custodian. The custodian holds the LTC in a secure reserve, ensuring that the wrapped Litecoin (WLTC) is fully backed by actual LTC."
    },
    {
        "title":"Minting",
        "description":"Once the LTC is received and verified by the custodian, WLTC is minted. This process involves creating an equivalent amount of WLTC tokens on the Ethereum blockchain (or another blockchain supporting smart contracts). Each WLTC is pegged 1:1 with LTC, ensuring that the value of WLTC is always backed by real Litecoin."
    },
    {
        "title":"Burning",
        "description":"When a user wants to convert their WLTC back to LTC, they initiate a burn transaction. This process destroys the specified amount of WLTC tokens. Once the burn is confirmed, the custodian releases the equivalent amount of LTC from the reserve to the user, completing the cycle."
    },
    {
        "title":"Staking",
        "description":"WLTC can also be used for staking, allowing users to earn rewards by participating in network security and consensus mechanisms. Staking WLTC provides an opportunity to earn additional returns on your holdings while supporting the network."
    }
]

const questions = [
    {
        "question":"1. What is Wrapped Litecoin (WLTC), and why was it created?",
        "answar":"WLTC is a token built on the Ethereum blockchain by Coinut and pegged to Litecoin in the proportion of 1:1. The asset brings more liquidity and value both to LTC and ETH ecosystems.",
        "links": [
            { text: "Coinut", url: "https://coinut.com/" }
        ]    
    },
    {
        "question":"2. Why should I use WLTC?",
        "answar":"The main advantage is that WLTC allows Litecoin to be used in the Ethereum ecosystem. It enables users to earn interest, provide liquidity, and access various DeFi services.",
        "links":[],
    },
    {
        "question":"3. How can I obtain WLTC?",
        "answar":"You can obtain WLTC through Coinut.",
        "links": [
            { text: "Coinut", url: "https://coinut.com/wltc-landing-page" }
        ]      
    },
    {
        "question":"4. What is the contract address for WLTC?",
        "answar":"The contract address for WLTC is 0x408133907cd3A75db2B031973b9ea1C084500a15. Always verify the contract address through official Coinut channels to avoid scams.",
        "links":[],
    },
    {
        "question":"5. Where can I find more information?",
        "answar":"Please refer to this FAQ Page for the full list of WLTC FAQs.",
        "links": [
            { text: "WLTC FAQs.", url: "https://coinut.zendesk.com/hc/en-us/categories/36065959548825-Wrapped-Litecoin-WLTC" }
        ]        
    }
]

export default function LandingPage(){
    const [currentStep, setCurrentStep] = useState(1)
    const [openAnswars, setOpenAnswars] = useState(Array(questions.length).fill(false))

    const handleToggle = (index) => {
        setOpenAnswars((prev) => {
          const newState = [...prev];
          newState[index] = !newState[index];
          return newState;
        });
      };

    const renderAnswerWithLinks = (answer, links) => {
        let renderedAnswer = answer;
    
        // Replace each specified text with anchor tags
        links.forEach(link => {
            const regex = new RegExp(link.text, 'g'); // Create a regex to replace all occurrences
            renderedAnswer = renderedAnswer.replace(regex, `<a href="${link.url}" target="_blank" rel="noopener noreferrer">${link.text}</a>`);
        });
    
        // Render the HTML string safely using 'dangerouslySetInnerHTML'
        return <span dangerouslySetInnerHTML={{ __html: renderedAnswer }} />;
    };

    return(
        <>
            <div id="LandingPage" className='landingPage'>
                <div className='header-section'>
                    <Header />
                </div>

                <div className='firstSectionContainer'>
                    <div className='first-section'>
                        <div className='container'>
                            <p>
                                Elevating <br/>
                                Litecoin <br/>
                                to New Heights!
                            </p>
                            <div className='button-container'>
                                <div>
                                    <a>
                                    Liquidity Amplification
                                    </a>
                                </div>
                                <div className='middle-button'>
                                    <a>
                                    Smart Contract Compatibility
                                    </a>
                                </div>
                                <div>
                                    <a>
                                    Ethereum Integration
                                    </a>
                                </div>
                            </div>
                            <div className='read-more'>
                                <div>
                                    <a href='https://coinut.com/wltc-landing-page' target='_blank'>
                                        Get WLTC
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='secondSectionContainer'>
                    <div className='second-section sectionContainer'>
                        <div className='titleContainer'>
                            <p className='section-title'>
                                Why do we need Wrapped
                                <br/>
                                Litecoin (WLTC)?
                            </p>
                        </div>
                        <div className='cardsContainer'>
                            <div className='cardContianer yellowCard'>
                                <div className='imageContainer'>
                                    <img src={liquidityImage} className='top'/>
                                </div>
                                <div className='contentContainer'>
                                    <p className='title'>
                                        Liquidity Amplification
                                    </p>
                                    <p className='description'>
                                    WLTC enhances liquidity within the Ethereum ecosystem by bridging
                                    Litecoin's substantial trading volume from centralized exchanges to decentralized ones (DEXs)
                                    and financial applications. This facilitates seamless token trades using Litecoin on decentralized platforms.
                                    </p>
                                    <div className='lineContainer'>
                                        <div className='yellowLine'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='cardContianer pinkCard'>
                                <div className='contentContainer'>
                                    <p className='title'>
                                        Smart Contract Compatibility
                                    </p>
                                    <p className='description'>
                                        By standardizing Litecoin into the ERC20 format, WLTC facilitates the integration of Litecoin transfers into smart contracts. This simplifies the development of decentralized applications (DApps) and enables more efficient execution of complex transactions involving Litecoin.
                                    </p>
                                    <div className='lineContainer'>
                                        <div className='pinkLine'></div>
                                    </div>
                                </div>
                                <div className='imageContainerBottom'>
                                    <img src={smartBackgroundImage} className='bottom'/>
                                </div>
                            </div>
                            <div className='cardContianer greenCard'>
                                <div className='imageContainer'>
                                    <img src={eThereUmImage} className='top'/>
                                </div>
                                <div className='contentContainer'>
                                    <p className='title'>
                                        Ethereum Integration
                                    </p>
                                    <p className='description'>
                                        With WLTC, Litecoin transactions become compatible with Ethereum's
                                        infrastructure, eliminating the need for separate node management and transaction support for
                                        various currencies. Exchanges, wallets, and payment applications can now streamline their
                                        operations by focusing solely on Ethereum node maintenance.
                                    </p>
                                    <div className='lineContainer'>
                                        <div className='greenLine'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='thirdSectionContainer'>
                    <div className='thirdSection sectionContainer'>
                        <div className='titleContainer'>
                            <p className='section-title'>
                                HOW IT WORKS
                            </p>
                        </div>
                        <div className='cardsContainer'>
                            <div className='leftCardContainer'>
                                <div className='container'>
                                    <div className='topContainer'>
                                        <div className='yellowBlur'></div>
                                        <img className='miningImage' src={mintingImage} />
                                        <div className='vectorsContainer'>
                                            <div className='vectorContainer yellowContainer'>
                                                <p>
                                                    Receiving
                                                </p>
                                                <div className='victor'>
                                                    <img src={yellowVector} />
                                                </div>

                                            </div>
                                            <div className='vectorContainer blueContainer'>
                                                <p>
                                                    Minting
                                                </p>
                                                <div className='victor'>
                                                    <img src={blueVector} />
                                                </div>

                                            </div>
                                            <div className='vectorContainer pinkContainer'>
                                                <p>
                                                    Burning
                                                </p>
                                                <div className='victor'>
                                                    <img src={pinkVector} />
                                                </div>

                                            </div>
                                            <div className='vectorContainer orangeContainer'>
                                                <p>
                                                    Staking
                                                </p>
                                                <div className='victor'>
                                                    <img src={orangeVector} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='contentsContainer'>
                                        <div className='mintingTitleContainer'>
                                            <p className='mintingTitle'>
                                                {WLTCSteps[currentStep].title}
                                            </p>
                                        </div>
                                        <div className='mintingDescriptionContainer'>
                                            <p className='mintingDescription'>
                                                {WLTCSteps[currentStep].description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='arrowsContainer'>
                                        <div className='leftArrow' onClick={()=>{setCurrentStep(currentStep === 0 ? WLTCSteps.length -1 : currentStep-1)}}></div>
                                        <div className='rightArrow' onClick={()=>{setCurrentStep(currentStep === WLTCSteps.length -1 ? 0 : currentStep+1)}}></div>
                                    </div>
                                </div>
                            </div>
                            <div className='rightCardContainer'>
                                <div className='topCardContainer'>
                                    <div className='leftCard'>
                                        <img className='img' src={litecoingImage} />
                                    </div>
                                    <div className='rightCard'>
                                        <img className='img' src={keyImage} />
                                    </div>
                                </div>
                                <div className='bottomCardContainer'>
                                    <img className='img' src={coreImage} />
                                </div>
                            </div>
                        </div>
                        <div className='summary'>
                            <div className='summaryContainer'>
                                <p className='title'>Summary</p>
                                <div className='summaryCardsContainer'>
                                    <div className='cardContainer recivingCardContainer'>
                                        <div className='summaryGridContainerParent'>
                                            <div></div>
                                            <div className='summaryGridContainer'>
                                                <p className='cardTitle'>
                                                    Receiving
                                                </p>
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <div>
                                                <p className='cardDescription'>
                                                    LTC is deposited with a secure custodian
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cardContainer mintingCardContainer'>
                                        <div className='summaryGridContainerParent'>
                                            <div></div>
                                            <div className='summaryGridContainer'>
                                                <div></div>
                                                <p className='cardTitle'>
                                                    Minting
                                                </p>
                                                <div></div>
                                            </div>
                                            <div>
                                                <p className='cardDescription'>
                                                    WLTC is created, backed 1:1 by the LTC in reserve.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cardContainer burningCardContainer'>
                                        <div className='summaryGridContainerParent'>
                                            <div></div>
                                            <p className='cardDescription'>
                                                WLTC is destroyed to release the equivalent amount of LTC back to the user.
                                            </p>
                                            <div className='summaryGridContainer'>
                                                <p className='cardTitle'>
                                                    Burning
                                                </p>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cardContainer stakingCardContainer'>
                                        <div className='summaryGridContainerParent'>
                                            <div></div>
                                            <div>
                                            <p className='cardDescription'>
                                                Staking: Users can stake WLTC to earn rewards and support network security.
                                            </p>
                                            </div>
                                            <div className='summaryGridContainer'>
                                                <div></div>
                                                <p className='cardTitle'>
                                                    Staking
                                                </p>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='qaSection'>
                    <div className='sectionContainer'>
                        <div className='titleContainer'>
                            <p className='title section-title' style={{textTransform:'none'}}>FAQs</p>
                        </div>
                        <div className="qasContainer">
                        {questions.map((question, index) => (
                            <div className="flexContainer" key={index}>
                                <div className="qaContainer">
                                    <div className="questionContainer">
                                        <p className="question" onClick={() => handleToggle(index)}>
                                            {question.question}
                                        </p>
                                        <div className={openAnswars[index] ? 'closeArrow' : 'openArrow'} onClick={() => handleToggle(index)}></div>
                                    </div>
                                    {openAnswars[index] ? (
                                        <p className="answar">
                                            {renderAnswerWithLinks(question.answar, question.links)}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        ))}</div>  
                    </div>
                </div>
                <div className='fourthSectionContainer'>
                    <div className='fourthSection'>
                    <div className='blur'></div>
                        <div className='wlitecoin-cover'>
                            <div className='wlitecoin-logo'>
                                <img src={wlitecoinLogo} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='joinCommunity'>
                    <div className='title'>
                        <p className='title section-title'>JOIN THE COMMUNITY</p>
                    </div>
                    <div className='buttonsContainer'>
                        <div className='button'>
                            <div>
                                <a href='https://www.litecoin.net/' target='_blank'>
                                    Litecoin foundation
                                </a>
                            </div>
                        </div>
                        <div className='button'>
                            <div>
                                <a href='https://litecointalk.io/t/rediscover-the-heart-of-crypto-with-litecoin-the-true-spirit-of-cyberpunk-lives-here/63104' target='_blank'>
                                    Litecoin Talk
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='partnersSection'>
                    <div className='partnersCover'>
                        <img src={partnersCover} />
                    </div>      
                    <div className='partnersContainer'>
                        <div className='sectionContainer'>
                            <div className='titleContainer'>
                                <p className='title section-title'>
                                    PARTNERS
                                </p>
                                <div className='cardsContainer'>
                                    <div className='cardContainer fireblocksCardContainer'>
                                        <div className='card fireblocksCard'>
                                            <img src={fireblocksLogo} className='img'/>
                                            <p>
                                                <a href='https://www.fireblocks.com/' target='_blank'>
                                                    Fireblocks
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='cardContainer coinutCardContainer'>
                                        <div className='card coinutCard'>
                                            <img src={coinutLogo} className='img'/>
                                            <p>
                                                <a href='https://coinut.com/' target='_blank'>
                                                    Coinut
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='cardContainer coinbaseCardContainer'>
                                        <div className='card coinbaseCard'>
                                            <img src={coinbaseLogo} className='img'/>
                                            <p>
                                                <a href='https://www.coinbase.com/prime/custody' target='_blank'>
                                                    Coinbase
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footerSection'>
                    <div className='sectionContainer'>
                        <div className='footerContainer'>
                            <a href='https://coinut.com/wltc-landing-page' target='_blank'>Get WLTC</a>
                            <img src={footerBoxLogo} />
                            <a href='https://coinut.zendesk.com/hc/en-us/articles/32150952264089-Data-Protection-Notice-Customers-and-Visitors' target='_blank'>Privacy</a>
                            <img src={footerBoxLogo} />
                            <a href='https://coinut.zendesk.com/hc/en-us/articles/115008686648-Terms-of-Services' target='_blank'>T&C</a>
                            <img src={footerBoxLogo} />
                            <a href=' https://coinut.zendesk.com/hc/en-us/articles/360001416133-Trading-fees' target='_blank'>Fees</a>
                            <img src={footerBoxLogo} />
                            <a href='https://coinut.zendesk.com/hc/en-us/articles/33904740484121-Risk-Disclosure-Statement-for-Wrapped-Litecoin' target='_blank'>Risk Disclosure</a>
                            <img src={footerBoxLogo} />
                        </div>
                        <div className='copyrightContainer'>
                            <p className='text'>
                            2024 © XXXXXXXXXXXXXXX
                            </p>
                            <div className='iconsContainer'>
                                <a href='https://x.com/coinut_exchange' target='_blank'>
                                    <img src={xLogo} />
                                </a>
                                <a href='https://t.me/coinut' target='_blank'>
                                    <img src={telegramLogo} />
                                </a>
                                <a href='http://facebook.com/CoinutExchange' target='_blank'>
                                    <img src={facebookLogo} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}